import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Chart from '../components/Chart';
import config from 'react-global-configuration';



export default class Home extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            loadingData: true,
            measurementsSensor1: [],
            latestDataSensor1: [],
            measurementsSensor2: [],
            latestDataSensor2: [],
            measurementsSensor3: [],
            latestDataSensor3: [],
        }

        this.getSensorData(1, "measurementsSensor1")
        this.getSensorData(2, "measurementsSensor2")
        this.getSensorData(3, "measurementsSensor3")
        this.startLiveUpdates()
    }

    getSensorData = (sensor,sensorName) => {
        fetch(config.get('apiUrl')+'measurements/'+sensor)
        .then(res=>res.json())
        .then((data)=> {

          var newData = data
          newData.sort(function (a, b){
            return Date.parse(a.takenAt) - Date.parse(b.takenAt)
          })
          this.setState({
              [sensorName]:newData,
              loadingData: false
            })
          
        })
    }

    getLatestMeasurement = (sensor, sensorName) => {
        fetch(config.get('apiUrl')+'latestMeasurements/'+sensor)
        .then(res=>res.json())
        .then((data)=> {
                var currentData = this.state[sensorName]
                if (data.length > 0){
                    data.forEach(element => {
                        currentData.push(element)
                    })
                    var sliceStart = currentData.length - 500
                    if (sliceStart < 0) sliceStart = 0
                    var newData = currentData.slice(sliceStart, currentData.length)
                    newData.sort(function (a, b){
                        return Date.parse(a.takenAt) - Date.parse(b.takenAt)
                    })
                    
                    this.setState({
                        [sensorName]: newData
                    })
                }
            })
    }

    startLiveUpdates = () => {
        let interval = setInterval(() => {
            this.getLatestMeasurement(1, "measurementsSensor1")
            this.getLatestMeasurement(2, "measurementsSensor2")
            this.getLatestMeasurement(3, "measurementsSensor3")
        },10000)
    }

    render() {

        if (this.state.loadingData){
            return <Container className="page-container" fluid> 
        <Row>
            <Col className="introtext">
             Loading data...
            </Col>
        </Row>
        </Container>
        }

        return <Container className="page-container" fluid> 
        <Row>
            <Col className="introtext">
            <h1>Power monitor</h1>
                {this.state.measurementsSensor1.length > 0 ? 
                <Chart name="Sensor 1 Irms" element="irms" sensorId="1" sensorData={this.state.measurementsSensor1} latestData={this.state.latestDataSensor1}/> : ''}
                {this.state.measurementsSensor2.length > 0 ? 
                <Chart name="Sensor 2 Irms" element="irms" sensorId="2" sensorData={this.state.measurementsSensor2} latestData={this.state.latestDataSensor2}/>: ''}
                {this.state.measurementsSensor3.length > 0 ? 
                <Chart name="Sensor 3 Irms" element="irms" sensorId="3" sensorData={this.state.measurementsSensor3} latestData={this.state.latestDataSensor3}/>: ''}

            </Col>
        </Row>
        </Container>

    }
}