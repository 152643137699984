import React from 'react';
import config from 'react-global-configuration';
import Main from './Main';

//config.set({apiUrl: 'http://localhost:8081/api/v1/'})
config.set({apiUrl: 'https://power-api.etnobird.com/api/v1/'})

function App() {
  return (
    <div>
      <Main/>
    </div>
  );
}

export default App;
