import { STORE_USER } from './../actions/index'

const initalState = {
    user: ''
}

function rootReducer(state = initalState, action) {
    if (action.type === STORE_USER){
        state.user = action.payload
    }
    return {...state}
}

export default rootReducer